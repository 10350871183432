h1 {
    color: #DC5F5F;
}

h2 {
    color: #DC5F5F;
}

.navigation {
    width: 100%;
    position: relative;
    min-height: 76px;
}

#navbarNav {
    justify-content: flex-end;
}

.navbar-light .navbar-brand {
    color: #DC5F5F;
}

.navbar-nav .nav-link {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    background-color: #DC5F5F;
    color: #FFFFFF;
}

.navbar-light, .navbar-nav, .nav-link {
    color: #333333;
    font-weight: 600;
}

.bg-secondary {
    background-color: #DC5F5F!important;
}

#home {
    background-image: url("IMG_landing.jpg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-details {
    position: absolute;
    width: 100%;
    max-width: 100%;
    top: 53%;
    margin:auto;
    text-align: center;
    color: #FFFFFF;
    left: 0;
    right: 0;
    
    background-color: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.home-details h1 {
    color: #FFFFFF;
    font-size: xxx-large;
    letter-spacing: .10em;
    text-transform: uppercase;
}

.home-details h4 {
    letter-spacing: .10em;
    text-transform: uppercase;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.home-details .btn-outline-primary {
    color: #FFFFFF;
    border-color: #FFFFFF;
    font-weight: 600;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}

.home-details a:hover {
    background-color: #DC5F5F;
    border-color: #DC5F5F;
}

#portrait {
    max-width: 100%;
    height: auto;
}

.fa-quote-left {
    color: #DC5F5F;
}

#feet {
    max-width: 100%;
    height: auto;
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #DED1C280;
}

.carousel-dark .carousel-indicators .active {
    background-color: #DC5F5F;
}

.carousel-inner {
    height: 250px;
}  

@media (min-width: 576px) { 
    #portrait {
        max-width: 100%;
        height: auto;
    }

    #feet {
        max-width: 100%;
        height: auto;
    }

    .carousel-inner {
        height: 300px;
    }  
}

@media (min-width: 768px) { 
    #portrait {
        max-width: 100%;
        width: 296px;
        height: auto;
    }

    #feet {
        max-width: 60%;
        height: auto;
    }
    
    .carousel-inner {
        height: 225px;
    }  
}

@media (min-width: 992px) { 
    #portrait {
        max-width: 100%;
        width: 356px;
        height: auto;
    }

    #feet {
        max-width: 60%;
        height: auto;
    }

    .carousel-inner {
        height: 200px;
    }    
}

@media (min-width: 1200px) { 
    #portrait {
        max-width: 100%;
        width: 356px;
        height: auto;
    }

    #feet {
        max-width: 60%;
        height: auto;
    }

    .carousel-inner {
        height: 200px;
    }    
}

@media (min-width: 1400px) { 
    #portrait {
        max-width: 100%;
        width: 356px;
        height: auto;
    }

    #feet {
        max-width: 60%;
        height: auto;
    }

    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1140px;
    }

    .carousel-inner {
        height: 200px;
    }    
}

#home, #courses, #specials, #about-me, #moon-yoga {
    min-height: calc(100vh - 76px);
}

#about-me, #contact {
    background-color: #DED1C280;
}

#courses .list-group-item.disabled {
    text-decoration: line-through;
}

#courses .bg-light .list-group-item {
    background-color: #f8f9fa;
}

/* https://github.com/twbs/bootstrap/issues/35900 */
[id=about-me], [id=courses], [id=specials], [id=moon-yoga], [id=contact]  {
    scroll-margin-top: 4rem;
}

.contact-card {
    background: #FFFFFF;
    border-radius: 0;
    padding: 60px 10px;
    margin: 10px 10px;
}

.contact-card .text{
    margin: 20px 0px;
}

.contact-card .fa-3x {
     color: #4183D7;
}